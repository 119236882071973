import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './store';

export function mainTableFilterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '1',
      startParamsField: 'startTime',
      endParamsField: 'endTime',
      data: [
        {
          value: '1',
          label: '创建时间',
        },
      ],
    },
    {
      type: 'select',
      field: 'warehouseId',
      label: '仓库',
      allowClear: false,
      onChangeCallback: (warehouseIds: string) => {
        context.getDataAfterWarehouse(warehouseIds);
        context.programme.filterItems.updateFilterItem([
          {
            field: 'ownerId',
            value: undefined,
          },
        ]);
      },
    },
    {
      type: 'select',
      field: 'approveState',
      label: '审核状态',
      mode: 'multiple',
      data: [
        {
          value: '1',
          label: '未审核',
        },
        {
          value: '2',
          label: '已审核',
        },
      ],
    },
    {
      field: 'cloudMaterialOrderNo',
      type: 'input',
      label: '单据编号',
    },
    {
      field: 'materialName',
      type: 'input',
      label: '物料名称',
    },
    {
      field: 'ownerId',
      type: 'select',
      label: '货主',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'orderType',
      label: '单据类型',

    },
  ];
}
