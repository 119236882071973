import type { DictData } from 'egenie-common';
import { objToDict, request } from 'egenie-common';

export const getMaterialType = () => {
  return request<DictData>({
    method: 'GET',
    url: '/api/cloud/baseinfo/rest/material/order/material/type',
  })
    .then((data) => {
      return objToDict(data);
    });
};

export const getOrderType = () => {
  return request<DictData>({
    method: 'GET',
    url: '/api/cloud/baseinfo/rest/material/order/order/type',
  })
    .then((data) => {
      return objToDict(data);
    });
};
