import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructureModel, Programme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { formatWarehouseChoice, getOwner, getWarehouse, logSubGrid } from '../../utils';
import { mainTableButtons } from './mainTableButtons';
import { mainTableColumns } from './mainTableColumns';
import { subTableMaterialDetailGrid } from './subTableMaterialDetailGrid';
import { mainTableFilterItems } from './mainTableFilterItems';
import type { MaterialOrder, MaterialOrderDetail } from './types';
import { AddAndEditStore } from './addAndEdit/addAndEditStore';
import { getOrderType } from './constants';
import { Modal } from 'antd';

export class Store {
  public addAndEditStore = new AddAndEditStore();

  constructor() {
    // 仓库
    getWarehouse()
      .then((info) => {
        this.programme.filterItems.updateFilterItem([
          {
            field: 'warehouseId',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseId'),
          },
        ]);
        this.getDataAfterWarehouse(formatWarehouseChoice(info, 'warehouseId'));
      });

    getOrderType().then((data) => {
      this.programme.filterItems.updateFilterItem([
        {
          field: 'orderType',
          data,
        },
      ]);
    });
  }

  public getDataAfterWarehouse = (warehouseId: string): void => {
    // 货主
    getOwner({ warehouseId })
      .then((info) => this.programme.filterItems.addDict({ ownerId: info }));
  };

  public gridModel = new MainSubStructureModel<MaterialOrder, [MaterialOrderDetail, any]>({
    buttons: mainTableButtons(this),
    pageId: '60231',
    grid: {
      columns: mainTableColumns(this),
      rows: [],
      primaryKeyField: 'cloudMaterialOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/materialInventory/index/1',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<MaterialOrder>>({
          url: '/api/cloud/baseinfo/rest/material/order/list',
          data: {
            ...rest,
            ...filterParams,
          },
          method: 'POST',
          headers: { warehouseId: filterParams?.warehouseId || '' },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        subTableMaterialDetailGrid(this),
        logSubGrid(30001, {}, 'warehouseId'),
      ],
    },
  });

  public programme = new Programme({
    gridModel: this.gridModel,
    filterItems: mainTableFilterItems(this),
    moduleName: 'egenieCloudWmsMaterialInventoryIndex',
  });

  @observable public showAddModal = false;

  @action public openAddModal = () => {
    this.showAddModal = true;
  };

  @action public closeAddModal = () => {
    this.showAddModal = false;
  };

  @action public addModalCallback = () => {
    this.closeAddModal();
    this.programme.gridModel.onRefresh();
  };

  @observable public showEditModal = false;

  @observable public mainItem: MaterialOrder = null;

  @action public editModalCallback = () => {
    this.programme.gridModel.onRefresh();
  };

  @action
  public handleDelete = (item: MaterialOrder) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除单据吗？',
      onOk: async() => {
        await request({
          method: 'POST',
          url: '/api/cloud/baseinfo/rest/material/order/delete',
          data: { cloudMaterialOrderId: item.cloudMaterialOrderId },
        });
        this.programme.gridModel.onRefresh();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };
}
