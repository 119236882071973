import { message, Modal } from 'antd';
import { request } from 'egenie-common';
import type { Store } from './store';
import type { MaterialOrder } from './types';

export function mainTableButtons(context: Store) {
  return [
    {
      permissionId: '21',
      text: '审核',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: MaterialOrder[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.approveState == 2)) {
          const error = '存在已经审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认审核吗?',
          onOk: () => request({
            url: '/api/cloud/baseinfo/rest/material/order/approve',
            method: 'post',
            data: {
              cloudMaterialOrderId: selectRows.map((item) => item.cloudMaterialOrderId)
                .join(','),
            },
          })
            .then(() => {
              context.programme.gridModel.onRefresh();
              message.success('审核成功');
            }),
        });
      },
    },
    {
      permissionId: '1',
      text: '新建',
      icon: 'icon-add',
      handleClick: () => {
        context.addAndEditStore.handleOpen();
        context.addAndEditStore.setCallback(context.programme.gridModel.onRefresh);
      },
    },
  ];
}
