import styles from './index.less';
import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';

export interface SlipProps {
  open: boolean;
  style?: CSSProperties | undefined;
  className?: string | undefined;
  children?: React.ReactNode;
}

export const Slip = (props: SlipProps) => {
  const {
    open,
    style,
    className,
    children,
  } = props;
  const [
    init,
    setInit,
  ] = useState(false);

  useEffect(() => {
    if (open) {
      setInit(true);
      return;
    }
    setTimeout(() => {
      setInit(false);
    }, 300);
  }, [open]);

  if (!init) {
    return null;
  }

  return (
    <div
      className={className}
      style={{
        overflowX: 'hidden',
        ...style,
      }}
    >
      <div
        className={`${open ? styles.slip_in : styles.slip_out} `}
      >
        {children}
      </div>
    </div>
  );
};
