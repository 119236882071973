import { ProgrammeComponent } from 'egenie-utils';
import { Provider } from 'mobx-react';
import React from 'react';
import { AddAndEditModal } from './addAndEdit/addAndEditModal';
import { Store } from './store';

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Provider store={store}>
      <ProgrammeComponent store={store.programme}/>
      <AddAndEditModal store={store.addAndEditStore}/>
    </Provider>
  );
}
