
import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { MaterialOrder, MaterialOrderDetail } from './types';
import type { ISubTableModel } from 'egenie-utils/dist/egGrid/mainSubStructure/subTableModel';

export function subTableMaterialDetailGrid(context: Store): ISubTableModel<MaterialOrder, MaterialOrderDetail> {
  return {
    filterItems: [],
    tab: {
      name: '商品明细',
      value: 'detail',
    },
    grid: {
      sumColumns: [
        'number',
        'sum',
      ],
      columns: [
        {
          name: '物料名称',
          key: 'materialName',
          width: 150,
        },
        {
          name: '规格',
          key: 'spec',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: '数量',
          key: 'num',
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/materialInventoryManage/index/2',
      rows: [],
      pageSize: 50,
      primaryKeyField: 'cloudMaterialOrderDetailId',
      sortByLocal: false,
      showCheckBox: false,
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          cond,
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<MaterialOrderDetail>>({
          url: '/api/cloud/baseinfo/rest/material/order/detail/list',
          method: 'POST',
          data: {
            ...cond,
            cloudMaterialOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
