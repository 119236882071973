import { Button } from 'antd';
import { Permission } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { MaterialOrder } from './types';

export function mainTableColumns(context: Store) {
  return [
    {
      name: '操作',
      key: 'operate',
      width: 150,
      formatter: ({ row }: { row: MaterialOrder; }) => {
        if (row.approveState === 2) {
          return null;
        }
        return (
          <>
            <Permission permissionId="60231_44">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  context.addAndEditStore.handleOpen(row);
                  context.addAndEditStore.setCallback(context.programme.gridModel.onRefresh);
                }}
                type="link"
              >
                编辑
              </Button>
            </Permission>

            <Permission permissionId="60231_45">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  context.handleDelete(row);
                }}
                type="link"
              >
                删除
              </Button>
            </Permission>
          </>
        );
      },
    },
    {
      name: '审核状态',
      key: 'approveStateName',
      width: 100,
    },
    {
      name: '单据编号',
      key: 'materialOrderNo',
      width: 150,
    },
    {
      name: '审核人',
      key: 'approveName',
      width: 150,
    },
    {
      name: '仓库',
      key: 'warehouseName',
      width: 150,
    },
    {
      name: '货主',
      key: 'ownerName',
      width: 150,
    },
    {
      name: '数量',
      key: 'totalNumber',
      width: 100,
    },
    {
      name: '创建时间',
      key: 'createTimeStr',
      width: 200,
    },
    {
      name: '审核时间',
      key: 'approveTimeStr',
      width: 200,
    },
    {
      name: '创建人',
      key: 'createUser',
      width: 100,
    },
    {
      name: '单据类型',
      key: 'orderTypeName',
    },
  ].map((info) => ({
    resizable: true,
    sortable: false,
    ...info,
  }));
}
