import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { EgGrid, FullModal, MainSubStructure } from 'egenie-utils';
import { observer, Provider } from 'mobx-react';
import React from 'react';

import type { AddAndEditStore } from './addAndEditStore';
import { Slip } from '../slip';
import { CloseOutlined } from '@ant-design/icons';

export const MaterialInventoryBaseInfo = observer(({ store }: { store: AddAndEditStore; }) => {
  const { formRef, warehouseData, ownerData, materialTypeData, onPressMaterialEntry, orderType, handleOpenGoodsCard, isEdit } = store;
  return (
    <Card
      style={{ height: 360 }}
      title="基础信息"
    >
      <Form
        labelCol={{ span: 8 }}
        layout="horizontal"
        ref={formRef}
        wrapperCol={{ span: 16 }}
      >
        <Row>
          <Col span={6}>
            <Form.Item
              label="仓库"
              name="warehouseId"
              rules={[
                {
                  required: true,
                  message: '请选择仓库',
                },
              ]}
            >
              <Select
                options={warehouseData}
                placeholder="请选择仓库"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="入库类型"
              name="orderType"
              rules={[
                {
                  required: true,
                  message: '请选择入库类型',
                },
              ]}
            >
              <Select
                options={orderType}
                placeholder="请选择"
              />
            </Form.Item>
          </Col>
          <Col span={6}>

            <Form.Item
              label="货主"
              name="ownerId"
              rules={[
                {
                  required: true,
                  message: '请选择货主',
                },
              ]}
            >
              <Select
                disabled={isEdit}
                optionFilterProp="label"
                options={ownerData}
                placeholder="请选择货主"
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              label="物料类型"
              name="materialType"
              rules={[
                {
                  required: true,
                  message: '请选择物料类型',
                },
              ]}
            >
              <Select options={materialTypeData}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item

              label="物料名称"
              name="materialName"
            >
              <Input
                onPressEnter={onPressMaterialEntry}
                placeholder="按Enter键录入"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item wrapperCol={{
              span: 16,
              offset: 8,
            }}
            >
              <Button
                onClick={handleOpenGoodsCard}
                type="primary"
              >
                批量添加
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>

  );
});

export const MaterialInventoryGoods = observer(({ store }: { store: AddAndEditStore; }) => {
  const { openGoodsCard, onSearchGoods, handleCloseGoodsCard, handleBatchAdd } = store;
  return (
    <Slip
      open={openGoodsCard}
      style={{
        height: 360,
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Card
        extra={<CloseOutlined onClick={handleCloseGoodsCard}/>}
        style={{ width: '100%' }}
        title="全部商品"
      >
        <div>
          <Input.Search
            addonBefore="物料名称"
            allowClear
            enterButton="查询"
            onSearch={onSearchGoods}
            placeholder="请输入物料名称"
            style={{ width: 304 }}
          />
          <Button
            className="ghost-bg-btn"
            onClick={handleBatchAdd}
            style={{ marginLeft: 8 }}

          >
            批量添加
          </Button>
        </div>

        <div style={{
          height: 240,
          marginTop: 8,
        }}
        >
          <EgGrid store={store.goodsStore.gridModel}/>
        </div>
      </Card>
    </Slip>
  );
});

export const AddAndEditModal = observer(({ store }: { store: AddAndEditStore; }) => {
  const { open, handleClose, handleSubmit, mainSubStructureModel, title, removeDisable, removeAllMaterial } = store;

  return (
    <Provider addAndEditStore={store}>
      <FullModal
        onCancel={handleClose}
        operation={(
          <Button
            onClick={handleSubmit}
            type="primary"
          >
            提交
          </Button>
        )}
        title={title}
        visible={open}
      >
        <div style={{
          padding: 16,
          backgroundColor: '#F0F0F0',
          height: '100%',
        }}
        >
          <div style={{ position: 'relative' }}>
            <MaterialInventoryBaseInfo store={store}/>
            <MaterialInventoryGoods store={store}/>
          </div>

          <Card
            style={{ marginTop: 16 }}
            title="商品明细"
          >
            <Button
              disabled={removeDisable}
              onClick={removeAllMaterial}
              type="primary"
            >
              全部移除
            </Button>
            <div style={{ height: 400 }}>

              <MainSubStructure store={mainSubStructureModel}/>
            </div>
          </Card>
        </div>
      </FullModal>
    </Provider>
  );
});
