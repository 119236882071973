import type { FormInstance } from 'antd';
import { Button, InputNumber, message } from 'antd';
import type { PaginationData, ValueAndLabelData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { getWarehouse, getOwner } from '../../../utils';
import React from 'react';
import { Observer } from 'mobx-react';
import { formatNumber } from 'egenie-common';
import type { Material, MaterialOrder } from '../types';
import { getMaterialType, getOrderType } from '../constants';

export class AddAndEditStore {
  @observable public open = false;

  @observable public openGoodsCard = false;

  @observable public warehouseData: ValueAndLabelData = [];

  @observable public ownerData: ValueAndLabelData = [];

  @observable public materialTypeData: ValueAndLabelData = [];

  @observable public orderType: ValueAndLabelData = [];

  @observable public materialName?: string;

  @observable public materialOrder?: MaterialOrder;

  constructor() {
    getWarehouse()
      .then((data) => {
        this.warehouseData = data;
      });

    getOwner()
      .then((data) => {
        this.ownerData = data;
      });

    getMaterialType().then((data) => {
      this.materialTypeData = data;
    });

    getOrderType().then((data) => {
      this.orderType = data;
    });
  }

  public formRef = React.createRef<FormInstance>();

  private callback?: () => void ;

  @computed
  public get title() {
    return this.materialOrder ? '编辑物料出入库' : '新建物料出入库';
  }

  @computed
  public get isEdit() {
    return Boolean(this.materialOrder);
  }

  @computed
  public get removeDisable() {
    return this.mainSubStructureModel.gridModel.rows.length <= 0;
  }

  @action
  public handleOpen = (materialOrder?: MaterialOrder) => {
    this.open = true;
    this.materialOrder = materialOrder;
    this.getOrderDetailList();
    this.initialFormValue();
  };

  @action
  public handleClose = () => {
    this.open = false;
    this.formRef.current.resetFields();
    this.materialOrder = undefined;
    this.openGoodsCard = false;
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = [];
    this.goodsStore.gridModel.resetAll();
    this.goodsStore.gridModel.rows = [];
  };

  @action
  public handleOpenGoodsCard = async() => {
    await this.formRef.current.validateFields();
    this.openGoodsCard = true;
    this.onSearchGoods('');
  };

  @action
  public handleCloseGoodsCard = () => {
    this.openGoodsCard = false;
  };

  @action
  public getOrderDetailList = async() => {
    if (!this.materialOrder) {
      return;
    }
    const result = await request<PaginationData<Material>>({
      url: '/api/cloud/baseinfo/rest/material/order/detail/list',
      method: 'POST',
      data: {
        cloudMaterialOrderId: this.materialOrder.cloudMaterialOrderId,
        page: 1,
        pageSize: 10000,
      },
    });
    this.mainSubStructureModel.gridModel.rows = result.data.list;
  };

  @action
  public initialFormValue = () => {
    if (!this.materialOrder) {
      return;
    }

    this.formRef.current.setFieldsValue({
      ownerId: this.materialOrder.ownerId,
      orderType: `${this.materialOrder.orderType}`,
      warehouseId: this.materialOrder.warehouseId,
    });
  };

  @action
  public removeAllMaterial = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = [];
  };

  public setCallback = (callback: () => void) => {
    this.callback = callback;
  };

  public mainSubStructureModel = new MainSubStructureModel<Material>({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'operation',
          frozen: true,
          width: 80,
          name: '操作',
          formatter: ({ rowIdx }) => {
            return (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  this.mainSubStructureModel.gridModel.rows.splice(rowIdx, 1);
                }}
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        {
          name: '物料名称',
          key: 'materialName',
          width: 180,
        },
        {
          name: '规格',
          key: 'spec',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 100,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: '数量',
          key: 'number',
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={99999}
                      min={0}
                      onChange={(value) => {
                        runInAction(() => {
                          this.mainSubStructureModel.gridModel.rows[rowIdx].num = formatNumber(value);
                        });
                      }}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].num}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsValueAddedMaterialId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: false,
      showPagination: false,
      showPager: true,
      setColumnsDisplay: true,
      showRefresh: false,
      gridIdForColumnConfig: '/egenie-cloud-wms/materialInventoryManage/index/2',
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });

  public goodsStore = new MainSubStructureModel<Material >({
    grid: {
      columns: [
        {
          key: 'materialName',
          name: '物料名称',
          width: 200,
        },
        {
          key: 'spec',
          name: '规格',
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
      ].filter(Boolean)
        .map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
      primaryKeyField: 'wmsValueAddedMaterialId',
      sortByLocal: false,
      showPager: true,
      setColumnsDisplay: true,
      showRefresh: false,
      gridIdForColumnConfig: '/egenie-cloud-wms/materialInventoryManage/index/3',
    },
    api: {
      onQuery: (params) => {
        const formValues = this.formRef.current.getFieldsValue([
          'ownerId',
          'orderType',
          'materialType',
        ]);
        formValues.ownerIds = [formValues.ownerId];
        delete formValues.ownerId;
        return request({
          method: 'POST',
          url: '/api/cloud/baseinfo/rest/material/order/search',
          data: {
            ...params,
            ...formValues,
            materialName: this.materialName,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  @action
  public onSearchGoods = (value: string) => {
    this.materialName = value;
    this.goodsStore.gridModel.resetAll();
    this.goodsStore.onQuery();
  };

  @action
  public handleBatchAdd = () => {
    const list = [
      ...this.mainSubStructureModel.gridModel.rows,
      ...this.goodsStore.gridModel.selectRows,
    ];
    const result = _.uniqBy(list, 'wmsValueAddedMaterialId');
    this.mainSubStructureModel.gridModel.rows = result;
    this.goodsStore.gridModel.resetAll();
    this.handleCloseGoodsCard();
  };

  @action
  public onPressMaterialEntry = async() => {
    await this.formRef.current.validateFields();

    const formValues = this.formRef.current.getFieldsValue([
      'ownerId',
      'orderType',
      'materialType',
      'materialName',
    ]);
    if (!formValues.materialName) {
      message.warning('请输入物料名称');
      return;
    }

    formValues.ownerIds = [formValues.ownerId];
    delete formValues.ownerId;

    const result = await request<PaginationData<Material>>({
      method: 'POST',
      url: '/api/cloud/baseinfo/rest/material/order/search',
      data: {
        page: 1,
        pageSize: 100,
        ...formValues,
      },
    });
    const list = [
      ...this.mainSubStructureModel.gridModel.rows,
      ...result.data.list,
    ];
    const materialList = _.uniqBy(list, 'wmsValueAddedMaterialId');
    this.mainSubStructureModel.gridModel.rows = materialList;
  };

  public handleSubmit = async() => {
    await this.formRef.current.validateFields([
      'warehouseId',
      'orderType',
      'ownerId',
    ]);
    if (this.mainSubStructureModel.gridModel.rows.length <= 0) {
      message.warning('请选择物料');
      return;
    }
    const hasNoNumber = this.mainSubStructureModel.gridModel.rows.some((e) => !e.num || e.num <= 0);
    if (hasNoNumber) {
      message.warning('请输入商品明细的物料数量');
      return;
    }

    const formValues = await this.formRef.current.getFieldsValue();
    const data = {
      ...formValues,
      detail: this.mainSubStructureModel.gridModel.rows.map((e) => {
        return {
          // @ts-ignore
          cloudMaterialDetailId: e.cloudMaterialOrderDetailId,
          number: e.num,
          wmsValueAddedMaterialId: e.wmsValueAddedMaterialId,
          materialName: e.materialName,
          spec: e.spec,
          pic: e.pic,
        };
      }),
    };
    if (this.materialOrder) {
      data.cloudMaterialOrderId = this.materialOrder.cloudMaterialOrderId;
    }
    await request({
      method: 'POST',
      data,
      url: '/api/cloud/baseinfo/rest/material/order/add/or/edit',
    });
    message.success('操作成功');
    this.handleClose();
    this.callback && this.callback();
  };
}
